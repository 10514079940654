{
  "loading": "Loading…",
  "menu": {
    "home": "Home",
    "settings": "Settings",
    "logout": "Logout"
  },
  "home": {
    "title": "My expense reports"
  },
  "actions": {
    "new": "New expense report",
    "save": "Save this expense report",
    "edit": "Edit this expense report",
    "send": "Send the expense report",
    "can_send": "Once you have completed your expense report, click below to send it for approval."
  },
  "state": {
    "empty": {
      "title": "No expense report found",
      "description": "Looks like there is no expense report linked to your email address. Create one by clicking on 'New expense report'!"
    }
  },
  "form": {
    "approved_by": {
      "label": "Approved by",
      "hint": "Please specify the person or service who approved this expense report"
    },
    "description": {
      "label": "Description",
      "hint": "Please describe your expense report. Add relevant date and context."
    },
    "description_required": "A description is required",
    "approved_by_required": "The field approved by is required",
    "updated": "The expense report has been updated",
    "not_found": "Expense report not found",
    "locked": {
      "title": "Expense report sent",
      "text": "Your expense report has been sent, you are not able to edit it any more. If you made any mistake, please contact the person in charge of managing expense reports."
    }
  },
  "purchases": {
    "title": "Purchases",
    "new": "New purchase",
    "date": {
      "label": "Date",
      "hint": "Please specify the date this purchase was made",
      "required": "A date is required"
    },
    "type": {
      "label": "Type",
      "hint": "Please specify the type of purchase. E.g.: Transportation, Meal",
      "required": "A type is required"
    },
    "amount": {
      "label": "Amount",
      "hint": "Please specify the amount paid",
      "required": "An amount is required"
    },
    "description": {
      "label": "Description",
      "hint": "Please describe the purchase. Why it was made…",
      "required": "A description is required"
    },
    "document": {
      "label": "Document",
      "hint": "Please upload a proof of purchase. E.g. invoice, receipt…",
      "required": "A document is required."
    },
    "actions": {
      "label": "Actions"
    },
    "close": "Close",
    "save": "Save purchase",
    "delete_title": "Are you sure?",
    "delete_text": "You will not be able to recover this item.",
    "delete_yes": "Yes, delete this item",
    "delete_no": "No, keep it",
    "current_document": "Currently: "
  },
  "login": {
    "title": "Login",
    "subtitle": "Login on your expense report manager.",
    "email": "Your email address",
    "login": "Login",
    "email_required": "An email address is required",
    "success": {
      "title": "Email sent!",
      "description": "You will shortly receive an email containing a link to login onto your expense report manager."
    },
    "error": {
      "title": "An error occurred",
      "description": "Oops! An error occurred… Try refreshing the page. If the problem persist please contact your IT department.",
      "no_provider": "Oops… No provider where configured by your system administrator…"
    },
    "using": "Login using {provider}"
  },
  "welcome": {
    "title": "Welcome to your expense report manager \uD83C\uDF89 !",
    "text": "Welcome on your expense report manager. This is the first time you log in, please fill out this form in order to complete your account creation. Don't worry you will be able to change all of this later."
  },
  "settings": {
    "first_name": "First name",
    "last_name": "Last name",
    "bank_account": "Bank account details",
    "iban": "IBAN",
    "bic": "BIC",
    "save": "Save",
    "first_name_required": "Your first name is required",
    "last_name_required": "Your last name is required",
    "iban_required": "Your IBAN is required",
    "bic_required": "Your BIC is required",
    "updated": "Your settings have been updated",
    "error": "An error occurred while updating your settings",
    "title": "My settings",
    "language": "Language selection",
    "lang": {
      "en": "\uD83C\uDDEC\uD83C\uDDE7 English",
      "fr": "\uD83C\uDDEB\uD83C\uDDF7 French"
    }
  },
  "api": {
    "no_response": "No response from the API…",
    "document_not_found": "Document not found…"
  },
  "fields": {
    "id": "ID",
    "description": "Description",
    "status": "Status",
    "amount": "Amount",
    "date": "Date"
  },
  "status": {
    "pending": "Pending",
    "generated": "Generated",
    "draft": "Draft",
    "sent": "Sent",
    "viewed": "Viewed",
    "approved": "Approved",
    "partial": "Partial",
    "paid": "Paid",
    "received": "Received",
    "cancelled": "Cancelled",
    "voided": "Voided",
    "denied": "Denied",
    "refunded": "Refunded"
  }
}